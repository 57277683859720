import React from "react";
import { Button, Modal } from "react-bootstrap";

const ConfirmRemoveModal = (props) => {
  const { show, onHide, onConfirm } = props;
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Please Confirm</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you wish to remove this ?</Modal.Body>
        <Modal.Footer>
          <Button variant={"danger"} onClick={() => onConfirm()}>
            Remove
          </Button>
          <Button variant={"secondary"} onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmRemoveModal;
