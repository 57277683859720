import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";

const AccessProfileNewModal = (props) => {
  const { show, onHide, onSuccess } = props;
  const [name, setName] = useState("");

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>New Access Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Profile Name</div>
          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => onSuccess({ name: name })}>
            Save
          </Button>
          <Button variant={"secondary"} onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AccessProfileNewModal;
