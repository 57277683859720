import React, { useContext, useRef, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import CommonTable from "../tables/CommonTable";
import { Monitor } from "react-feather";
import { Button } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import api from "../../api";
import AccessProfileElementAddToExistingModal from "./AccessProfileElementAddToExistingModal";
import AccessProfileNewModal from "./AccessProfileNewModal";
import ConfirmRemoveModal from "./ConfirmRemoveModal";

const AccessProfilesTab = (props) => {
  const { id, type } = props;
  const { user } = useAuth();
  const navigate = useNavigate();
  const accessProfilesTableRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [showAddToExisting, setShowAddToExisting] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(null);
  //const [accessProfileToRemove, setAccessProfileToRemove] = useState(null);
  const notify = useContext(NotyfContext);

  const onCreateNew = (data) => {
    api.post(`accessprofiles`, { permissionName: data.name }).then((res) => {
      var accessProfileId = res.data.id;
      api
        .post(`accessprofiles/${accessProfileId}/${type}/link/${id}`)
        .then((resLink) => {
          notify.open({
            type: "success",
            message: "Changes Saved",
          });
          setShowModal(false);
          navigate(`/accessprofiles/edit?id=${accessProfileId}`);
        });
    });
  };

  const onAddToExisting = (data) => {
    api.post(`accessprofiles/${data.id}/${type}/link/${id}`).then((resLink) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setShowAddToExisting(false);
      accessProfilesTableRef.current.updateTable(1);
    });
  };

  const onRemove = () => {
    if (showConfirmRemove && showConfirmRemove.id) {
      api
        .post(`accessprofiles/${showConfirmRemove.id}/${type}/unlink/${id}`)
        .then((resLink) => {
          accessProfilesTableRef.current.updateTable(1);
          setShowConfirmRemove(null);
          notify.open({
            type: "success",
            message: "Changes Saved",
          });
        });
    }
  };

  const columns = [
    {
      Header: "Name",
      accessor: "permissionName",
      type: "link",
      link: "/accessprofiles/edit",
      stateId: "id",
    },
    {
      Header: "",
      accessor: "id",
      Cell: (cell) => {
        return (type === "assets" && cell.row.original.allAssets) ||
          (type === "people" && cell.row.original.allPeople) ||
          (type === "zones" && cell.row.original.allZones) ? (
          <div className="d-flex justify-content-end">
            <Button className="me-2" variant="danger" disabled>
              {`(All ${type.charAt(0).toUpperCase() + type.slice(1)})`}
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <Button
              className="me-2"
              variant={"danger"}
              onClick={() => {
                setShowConfirmRemove(cell.row.original);
              }}
            >
              Remove
            </Button>
          </div>
        );
      },
      disableSortBy: true,
    },
  ];

  return (
    <>
      <div className="d-flex justify-content-between pb-3">
        <h4 className="text-nowrap me-1">
          <Monitor size={18} /> Access Profiles
        </h4>
        <div>
          {user?.editAssets && (
            <Button
              onClick={() => {
                setShowAddToExisting(true);
              }}
              className="mx-1"
              variant="success"
            >
              Add to Existing
            </Button>
          )}
          {user?.editAssets && user?.createRules && (
            <Button
              onClick={() =>
                // navigate(`/accessprofiles/create?link=assets&linkId=${id}`)
                setShowModal(true)
              }
              className="mx-1"
            >
              Create New
            </Button>
          )}
        </div>
      </div>
      <div className="pt-3">
        <CommonTable
          ref={accessProfilesTableRef}
          apiName="accessprofiles"
          parentId={id}
          columns={columns}
        />
      </div>
      <ConfirmRemoveModal
        show={showConfirmRemove}
        onHide={() => setShowConfirmRemove(false)}
        onConfirm={onRemove}
      ></ConfirmRemoveModal>
      <AccessProfileNewModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSuccess={onCreateNew}
      ></AccessProfileNewModal>
      <AccessProfileElementAddToExistingModal
        show={showAddToExisting}
        onHide={() => setShowAddToExisting(false)}
        onSuccess={onAddToExisting}
        elementId={id}
      ></AccessProfileElementAddToExistingModal>
    </>
  );
};

export default AccessProfilesTab;
